import React from "react";
import { convertToKilobytes } from "../utilities";
import Modal from "./Modal";
import TextareaInput from "./TextareaInput";
import TextInput from "./TextInput";
import * as Yup from "yup";
import { Formik } from "formik";
import Filter from "../common/Filter";
import { ADD_DOC_AS_TEXT_ENABLED } from "../utilities/appSettings";

const fileContentOptions = [
  "Medication List",
  "History and Physical",
  "Therapy Eval and Notes",
  "Labs",
  "Nutrition",
  "Other",
  "All-in-one",
].map((content) => ({
  label: content,
  value: content,
}));

export default class FileInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDocumentFromTextModalOpen: false,
      isDocumentFromTextLoading: false,
    };
  }

  handleFileInputChange = (e) => {
    this.triggerFilesAdded(Array.from(e.target.files));
  };

  triggerFilesAdded = (newFiles) => {
    const { onFilesAdded } = this.props;
    onFilesAdded &&
      typeof onFilesAdded === "function" &&
      onFilesAdded(
        newFiles
          .filter((file) => !this.getNamesOfAddedDocuments().has(this.stripFileExtension(file.name)))
          .map((file) => ({ file, types: [] }))
      );
  };

  removeSingleFile = (index) => {
    const { onFileRemoved } = this.props;
    onFileRemoved && typeof onFileRemoved === "function" && onFileRemoved(index);
  };

  onClick = (e) => {
    e.target.value = null; // allows selecting same file from file picker
  };

  addGeneratedDocumentToDocuments = (fileName, fileContents) => {
    this.triggerFilesAdded([new File([fileContents], `${fileName}.txt`, { type: "text/plain;charset=utf-8" })]);
  };

  toggleDocumentFromTextModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      isDocumentFromTextModalOpen: !prevState.isDocumentFromTextModalOpen,
    }));
  };

  setDocumentFromTextLoadingIndicator = () => {
    this.setState((prevState) => ({ ...prevState, isDocumentFromTextLoading: true }));
  };

  submitDocumentFromTextModalForm = async (values, _actions) => {
    const { fileName, fileContents } = values;
    this.addGeneratedDocumentToDocuments(fileName, fileContents);
    this.toggleDocumentFromTextModal();
    this.setDocumentFromTextLoadingIndicator();
  };

  getNamesOfAddedDocuments = () => {
    const namesSet = new Set();
    this.props.files.forEach(({ file }) => namesSet.add(this.stripFileExtension(file.name)));
    return namesSet;
  };

  stripFileExtension = (name) => name.replace(/\.[^/.]+$/, "");

  setFileTypes = (fileIndex, fileTypes) => {
    const { onFileTypesChanged } = this.props;
    onFileTypesChanged && typeof onFileTypesChanged === "function" && onFileTypesChanged(fileIndex, fileTypes);
  };

  render() {
    const { isDocumentFromTextModalOpen, isDocumentFromTextLoading } = this.state;
    const { id, name, hideSelectedFiles, isFileLoading, files = [], touched = [], errors = [] } = this.props;
    return (
      <>
        <label
          className={`btn btn-default p-xs ld-ext-left ${
            isFileLoading && !isDocumentFromTextLoading ? "running w-lg" : ""
          }`}
          disabled={isFileLoading}
        >
          {!isFileLoading && (
            <input
              type="file"
              id={id || "custom-file-input"}
              name={name || "files"}
              accept=".doc,.docx,.pdf,.txt"
              style={{
                display: "none",
              }}
              multiple
              onClick={this.onClick}
              onChange={this.handleFileInputChange}
            />
          )}
          Add Documents
          {isFileLoading && !isDocumentFromTextLoading && <div className="ld ld-ring ld-spin"></div>}
        </label>
        {ADD_DOC_AS_TEXT_ENABLED && (
          <label
            className={`btn btn-default p-xs m-l ld-ext-left ${
              isFileLoading && isDocumentFromTextLoading ? "running w-xxl" : ""
            }`}
            onClick={() => this.toggleDocumentFromTextModal()}
            disabled={isFileLoading}
          >
            Add Document As Text
            {isFileLoading && isDocumentFromTextLoading && <div className="ld ld-ring ld-spin"></div>}
          </label>
        )}

        <Modal
          open={!isFileLoading && isDocumentFromTextModalOpen}
          onClose={() => this.toggleDocumentFromTextModal()}
          title="Generate Document From Text"
          description="Please provide a file name for the document and enter or paste the document's text."
        >
          <Formik
            initialValues={{
              fileName: "",
              fileContents: "",
            }}
            validationSchema={Yup.object().shape({
              fileName: Yup.string()
                .notOneOf(
                  this.getNamesOfAddedDocuments(),
                  "A document with the same name is already added. Please provide a different name."
                )
                .required("Please provide a name for the document file"),
              fileContents: Yup.string().required("Please enter or paste text of the document"),
            })}
            onSubmit={this.submitDocumentFromTextModalForm}
            render={({ values, handleSubmit, handleChange, touched, errors }) => (
              <div>
                <TextInput
                  name="fileName"
                  label="File Name"
                  {...{ values, touched, errors, handleChange }}
                  suffix=".txt"
                />
                <TextareaInput
                  name="fileContents"
                  label="Document Text"
                  {...{ value: values.fileContents, touched, errors, onChange: handleChange }}
                ></TextareaInput>
                <div>
                  <button type="button" onClick={handleSubmit} className="btn btn-primary">
                    Generate and Add The Document
                  </button>
                  <button type="button" onClick={this.toggleDocumentFromTextModal} className="btn btn-disabled m-l">
                    Cancel
                  </button>
                </div>
              </div>
            )}
          />
          <style jsx="true">
            {`
              .modal-header {
                text-align: left;
              }
              .modal-body > div > :nth-last-child(1) {
                text-align: right;
              }
              .modal-content {
                margin: 0 auto;
                max-width: 600px;
              }
            `}
          </style>
        </Modal>
        {!hideSelectedFiles && files ? (
          <div className="files-list m-t">
            <ul className="p-0">
              {files.map(({ file, types }, i) => {
                return (
                  <li className="d-flex justify-between align-center bg-light m-b-xs" key={file.name}>
                    <div className="m-l-sm">{file.name}</div>
                    <div className="d-flex align-center">
                      <div style={{ width: "330px" }}>
                        <Filter
                          selectHeight="38px"
                          isMulti
                          isSearchable={false}
                          className="m-xxs"
                          placeholder="Please specify the contents of the document"
                          key={file.name}
                          options={fileContentOptions}
                          onChange={(options) => {
                            this.setFileTypes(
                              i,
                              options.map((opt) => opt.value)
                            );
                          }}
                          onBlur={() => this.setFileTypes(i, types)}
                        />
                        {touched[i] && errors[i] && <div className="text-danger">{errors[i].types}</div>}
                      </div>
                      <div className="d-flex justify-end m-l-sm m-r-sm" style={{ minWidth: "60px" }}>
                        {convertToKilobytes(file.size)}Kb
                      </div>
                      <button
                        type="button"
                        className="close m-r-sm"
                        aria-label="Close"
                        onClick={() => this.removeSingleFile(i)}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : null}
      </>
    );
  }
}
