import React from "react";
import moment from "moment";
import Table from "../common/Table";
import Filter from "../common/Filter";
import { useFullName } from "../utilities";
import { Link } from "react-router-dom";
import DeleteIntakeButton from "./DeleteIntakeButton";
import { capitalize } from "lodash";
import LoadingIndicator from "../common/LoadingIndicator";
import { statusOptions } from "./constants";
import { connect } from "react-redux";
import { withMixpanel } from "react-mixpanel-browser";
import { setIntakeFilters } from "../store/actions/intake";
import { EDIT_INTAKE_ENABLED } from "../utilities/appSettings";

const config = {
  columns: [
    {
      title: "Date Created",
      key: "createdAt",
      transformer: (data) => moment.utc(data).local().format("MM/DD/YYYY"),
      sortValue: ({ createdAt }) => moment(createdAt).unix(),
      sortable: true,
    },
    {
      title: "Name",
      transformer: (data) => (
        <Link style={{ textTransform: "capitalize" }} to={`/intakes/${data.id}`}>
          {useFullName(data)}
        </Link>
      ),
      sortKey: ["lastName", "firstName"],
      sortable: true,
    },
    {
      title: "Facility",
      key: "facilityName",
      sortable: true,
    },
    {
      title: "Diagnosis",
      key: "primaryDiagnosis",
      transformer: (data) => data && `${data.description} (${data.code})`,
    },
    {
      title: "Primary Payer",
      key: "primaryPayer",
      transformer: (data) => data.displayName,
    },
    {
      title: "Status",
      key: "status",
      transformer: (data) => (data ? capitalize(data) : ""),
    },
    {
      title: "Referral Source",
      key: "referralSource",
    },
    {
      transformer: (intake) => {
        return (
          <Link to={`/intakes/edit/${intake.id}`}>
            <i className="fa fa-2x fa-pencil-square text-primary clickable" aria-label="Edit Document" />
          </Link>
        );
      },
      bodyClassName: "text-right",
      isTurnedOff: !EDIT_INTAKE_ENABLED,
    },
    {
      transformer: (intake) => {
        return <DeleteIntakeButton intake={intake} />;
      },
      bodyClassName: "text-right",
    },
  ].filter((col) => !col.isTurnedOff),
  defaultSort: {
    column: 0,
    direction: "descending",
  },
  search: {
    keys: [
      {
        name: "primaryPayer.name",
        weight: 1,
      },
    ],
    placeholder: "Search by Primary Payer",
  },
  category: "Intake",
};

export class IntakesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleFilterChange(filterKey, selectedValues) {
    const { user, mixpanel, intakeFilters } = this.props;
    const email = user.email;

    if (selectedValues.length > intakeFilters[filterKey].length) {
      const newFilter = selectedValues
        .filter((value) => !intakeFilters[filterKey].includes(value))
        .map((filters) => filters.value);

      mixpanel.track("Intake filter added", {
        email: email,
        [filterKey]: newFilter,
      });
    }
    this.props.setIntakeFilters(filterKey, selectedValues);
  }

  filterIntakes() {
    const { intakeFilters, intakesWithPrimaryPayerAndFacility } = this.props;
    const { status = [], facilities = [] } = Object.entries(intakeFilters).reduce(
      (selectedVals, [key, selected]) => ({
        ...selectedVals,
        [key]: selected.map(({ value }) => value),
      }),
      {}
    );

    return intakesWithPrimaryPayerAndFacility.filter((intake) => {
      return (
        (status.length === 0 || status.includes(intake.status)) &&
        (facilities.length === 0 || facilities.includes(intake.facilityId))
      );
    });
  }

  render() {
    const { intakeFilters, intakesWithPrimaryPayerAndFacility, facilities, isLoading } = this.props;

    let filterOptions = {
      status: statusOptions,
      facilities: facilities,
    };

    const visibleIntakes = this.filterIntakes();
    if (isLoading && intakesWithPrimaryPayerAndFacility.length === 0) {
      return <LoadingIndicator />;
    }

    return (
      <Table
        data={visibleIntakes}
        config={config}
        headerContent={
          <div className="flex-column">
            {Object.keys(filterOptions).map((filterKey) => {
              const label =
                filterKey === "intakeStatus" ? "Status" : filterKey.charAt(0).toUpperCase() + filterKey.slice(1);
              return (
                <Filter
                  className="flex-1-auto p-xs"
                  key={filterKey}
                  label={label}
                  value={intakeFilters[filterKey]}
                  options={filterOptions[filterKey]}
                  onChange={(values) => this.handleFilterChange(filterKey, values)}
                  isMulti
                />
              );
            })}
          </div>
        }
      />
    );
  }
}

IntakesList.defaultProps = {
  intakeFilters: {},
  intakesWithPrimaryPayerAndFacility: [],
  facilities: [],
};

function mapStateToProps(state) {
  const {
    filters: { intakeFilters },
  } = state;
  return {
    intakeFilters,
    user: state.user,
  };
}

export default connect(mapStateToProps, { setIntakeFilters })(withMixpanel(IntakesList));
