import React, { useContext, useState } from "react";
import moment from "moment";
import Table from "../common/Table";
import { DateFilter } from "../common/DateFilter";
import LoadingIndicator from "../common/LoadingIndicator";
import AssessmentsListDialog from "./AssessmentsListDialog";
import { useAssessmentsForStay } from "../api/caseManagementApi";
import { PatientStayContext } from "../PatientPage";

const config = {
  columns: [
    {
      title: "Assess Date",
      key: "assessDate",
      transformer: (data) => moment(data).format("M/DD/YYYY"),
      sortKey: "assessDate",
      sortable: true,
    },
    {
      title: "Completed Date",
      key: "completedDate",
      transformer: (data) => (data ? moment(data).format("M/DD/YYYY") : "N/A"),
      sortKey: "completedDate",
      sortable: true,
    },
    {
      title: "Description",
      key: "description",
      sortable: true,
      transformer: (data) => data || "No description",
    },
    {
      title: "PDPM HIPPS",
      key: "pdpm_hipps",
      sortable: true,
    },
    {
      title: "State RUG",
      key: "rug",
      sortable: true,
      transformer: (data) => data || "No State RUG",
    },
    {
      title: "State CMI",
      key: "cmi",
      sortable: true,
      //show "No State CMI" if cmi is 0, but it comes as string so convert to number first
      transformer: (data) => (Number(data) ? data : "No State CMI"),
    },
  ],
  defaultSort: {
    column: 0,
    direction: "descending",
  },
};

export const AssessmentsList = () => {
  const { stay } = useContext(PatientStayContext);

  const assessmentsQuery = useAssessmentsForStay(stay.id);
  const [filters, setFilters] = useState({ startDate: null, endDate: null });

  if (assessmentsQuery.isLoading) {
    return <LoadingIndicator />;
  }

  const availableAssessments = assessmentsQuery.data.filter((item) => {
    const date = moment(item.assessDate);
    return (
      (!filters.startDate || date.isSameOrAfter(filters.startDate, "day")) &&
      (!filters.endDate || date.isSameOrBefore(filters.endDate, "day"))
    );
  });

  const handleDateFilterChange = (selectedValue) => {
    setFilters((x) => ({ ...x, startDate: selectedValue.startDate, endDate: selectedValue.endDate }));
  };

  return (
    <>
      <div className="d-flex justify-end p-xs">
        <DateFilter endDate={filters.endDate} startDate={filters.startDate} setDateFilter={handleDateFilterChange} />
        <ul>
          <AssessmentsListDialog className="p-m" stay={stay} />
        </ul>
      </div>

      {availableAssessments.length > 0 ? (
        <Table data={availableAssessments} config={config} />
      ) : (
        <div style={{ marginTop: 20 }}>There is no data available.</div>
      )}
    </>
  );
};
