import React, { useContext, useState } from "react";
import moment from "moment";
import Table from "../common/Table";
import { DateFilter } from "../common/DateFilter";
import LoadingIndicator from "../common/LoadingIndicator";
import { PatientStayContext } from "../PatientPage";

const config = {
  columns: [
    {
      title: "Date",
      key: "date",
      transformer: (data) => moment(data).format("M/DD/YYYY"),
      sortValue: (data) => moment(data.date).unix(),
      sortable: true,
    },
    {
      title: "ICD 10",
      key: "code",
      sortable: true,
    },
    {
      title: "Description",
      key: "description",
      sortable: true,
    },
    {
      title: "Rank",
      key: "rank",
      sortable: true,
    },
  ],
  defaultSort: {
    column: 0,
    direction: "descending",
  },
  search: {
    keys: [
      {
        name: "description",
        weight: 1,
      },
    ],
    placeholder: "Search by description",
  },
};

export const Diagnoses = () => {
  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
  });

  const { services } = useContext(PatientStayContext);

  if (services === undefined) {
    return <LoadingIndicator />;
  }

  const handleDateFilterChange = (selectedValue) => {
    setFilters({ startDate: selectedValue.startDate, endDate: selectedValue.endDate });
  };

  const visibleDiagnoses = services.diagnoses.filter((diagnosis) => {
    const date = moment(diagnosis.date);
    return (
      (!filters.startDate || date.isSameOrAfter(filters.startDate, "day")) &&
      (!filters.endDate || date.isSameOrBefore(filters.endDate, "day"))
    );
  });

  return services.diagnoses.length > 0 ? (
    <>
      <div className="d-flex justify-end p-xs">
        <DateFilter endDate={filters.endDate} startDate={filters.startDate} setDateFilter={handleDateFilterChange} />
      </div>
      <Table data={visibleDiagnoses} config={config} />
    </>
  ) : (
    <div style={{ marginTop: 20 }}>There are no diagnoses for this patient.</div>
  );
};
