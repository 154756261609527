import React, { useContext, useEffect, useState } from "react";
import ServiceSummary from "../common/ServiceSummary";
import ServiceSummaryExpandable from "../common/ServiceSummaryExpandable";
import { useOrdersForStay, usePayers } from "../api/caseManagementApi";
import LoadingIndicator from "../common/LoadingIndicator";
import { PatientStayContext } from "../PatientPage";

export const StayServiceSummary = ({ payer, insurancePlans, isLoading }) => {
  const { stay, services, patientPayers } = useContext(PatientStayContext);

  const [payers, setPayers] = useState(undefined);

  const payersQuery = usePayers(patientPayers);
  const ordersQuery = useOrdersForStay(stay.id);

  const payersFetched = payersQuery.every((x) => x.isSuccess);

  useEffect(() => {
    if (payersFetched) {
      const data = payersQuery.flatMap((x) => x.data);
      setPayers(data);
    }
  }, [payersFetched]);

  if (isLoading || services === undefined || ordersQuery.isLoading || payersQuery.some((x) => x.isLoading)) {
    return <LoadingIndicator />;
  }

  let serviceSummaries = {};
  if (payer && payers && insurancePlans) {
    serviceSummaries = getServiceSummaries(
      payer.id,
      payers,
      insurancePlans,
      ordersQuery.data,
      services.diagnoses,
      services.notes
    );
  }

  const { current, old } = serviceSummaries;

  if (!payer) {
    return <div> No payer information to show.</div>;
  }

  return current ? (
    <>
      <h3 className="m-r-sm">Active Payer - {payer && payer.displayName}</h3>
      <ServiceSummary plans={current.plans} carveOuts={current.carveOuts} />
      {old.length > 0 && (
        <>
          <h3 className="m-r-sm">Inactive Payers</h3>
          {old.map((serviceSummary, i) => {
            const { payer: { id } = {} } = serviceSummary;
            return <ServiceSummaryExpandable key={id || `summary${i}`} {...serviceSummary} />;
          })}
        </>
      )}
    </>
  ) : (
    <div> No payer information to show.</div>
  );
};

const getServiceSummaries = (currentPayerId, payers, insurancePlans, orders = [], diagnoses = [], notes = []) => {
  const serviceSummaries = { old: [] };

  payers.forEach((p) => {
    const payerPlans = Object.values(insurancePlans.filter((x) => x.payerId === p.id) || {}).filter(
      (plan) => plan.rank
    );
    let plans = [];

    if (payerPlans.length) {
      const ordersByMcce = getOrdersByMcce(orders);
      const diagnosesByMcce = getDiagnosesByMcce(diagnoses);
      const notesByMcce = getNotesByMcce(notes);

      plans = payerPlans.map((plan) => ({
        ...plan,
        medicallyComplexCareEvents: plan.medicallyComplexCareEvents.map((mcce) => ({
          ...mcce,
          orders: (ordersByMcce[mcce.id] || []).sort(sortByDateDesc),
          diagnoses: (diagnosesByMcce[mcce.description] || []).sort(sortByDateDesc),
          notes: (notesByMcce[mcce.description] || []).sort(sortByDateDesc),
        })),
      }));
    }
    if (p.id === currentPayerId) {
      serviceSummaries.current = { payer: p, plans, carveOuts: [] };
    } else {
      serviceSummaries.old.push({ payer: p, plans, carveOuts: [] });
    }
  });

  return serviceSummaries;
};

const getOrdersByMcce = (orders) => {
  return orders.reduce((acc, order) => {
    order.mcceIds.forEach((mcceId) => {
      acc[mcceId] = [...(acc[mcceId] || []), order];
    });
    return acc;
  }, {});
};

const getDiagnosesByMcce = (diagnoses) => {
  return diagnoses.reduce((acc, diag) => {
    diag.mcceDescriptions.forEach((mcceDesc) => {
      acc[mcceDesc] = [...(acc[mcceDesc] || []), diag];
    });
    return acc;
  }, {});
};

const getNotesByMcce = (notes) => {
  return notes.reduce((acc, note) => {
    note.mcceDescriptions.forEach((mcceDesc) => {
      acc[mcceDesc] = [...(acc[mcceDesc] || []), note];
    });
    return acc;
  }, {});
};

const sortByDateDesc = (a, b) => (a.date < b.date ? 1 : a.date > b.date ? -1 : 0);
