export function isToggleTurnedOn(toggleName) {
  const fullToggleName = `REACT_APP_FT_${toggleName}`;
  if (process.env[fullToggleName] === undefined) {
    return true;
  }
  return Boolean(Number(process.env[fullToggleName]));
}

export const isToggleTurnedOnMemoized = (() => {
  const toggles = {};
  return (toggleName) => {
    if (toggles[toggleName] === undefined) {
      toggles[toggleName] = isToggleTurnedOn(toggleName);
    }
    return toggles[toggleName];
  };
})();

export function facilityToggle(toggleName, facilityId) {
  const fullToggleName = `REACT_APP_FT_${toggleName}`;
  if (process.env[fullToggleName] === undefined) {
    return true;
  }
  return process.env[fullToggleName].split(" ").includes(String(facilityId));
}
