export const CREATE_UPDATE = "CREATE_UPDATE";
export const FETCH_ALERTS = "FETCH_ALERTS";
export const FETCH_ALERT_CATEGORIES = "FETCH_ALERT_CATEGORIES";
export const FETCH_ALERT_STATUSES = "FETCH_ALERT_STATUSES";
export const FETCH_ALERT_TYPES = "FETCH_ALERT_TYPES";
export const FETCH_CONDITIONS = "FETCH_CONDITIONS";
export const FETCH_FACILITIES = "FETCH_FACILITIES";
export const FETCH_PATIENTS_BY_FACILITY = "FETCH_PATIENTS_BY_FACILITY";
export const FETCH_MEDS = "FETCH_MEDS";
export const FETCH_PAYER = "FETCH_PAYER";
export const FETCH_PAYERS = "FETCH_PAYERS";
export const FETCH_ORDERS = "FETCH_ORDERS";
export const FETCH_STAY = "FETCH_STAY";
export const FETCH_STAYS = "FETCH_STAYS";
export const FETCH_STAYS_BY_FACILITY = "FETCH_STAYS_BY_FACILITY";
export const FETCH_STAY_BY_PATIENT_AND_DATE = "FETCH_STAY_BY_PATIENT_AND_DATE";
export const FETCH_THERAPY_EVENTS = "FETCH_THERAPY_EVENTS";
export const FETCH_NURSE_NOTES = "FETCH_NURSE_NOTES";
export const FETCH_PLANS_FOR_PAYER = "FETCH_PLANS_FOR_PAYER";
export const FETCH_PLAN = "FETCH_PLAN";
export const FETCH_UPDATES = "FETCH_UPDATES";
export const SET_ALERTS_DATE_FILTER = "SET_ALERTS_DATE_FILTER";
export const SET_STAY_DATE_FILTER = "SET_STAY_DATE_FILTER";
export const SET_UPDATE_DATE_FILTER = "SET_UPDATE_DATE_FILTER";
export const UPDATE_STAY = "UPDATE_STAY";
export const FETCH_INTAKES = "FETCH_INTAKES";
export const FETCH_INTAKE = "FETCH_INTAKE";
export const CREATE_INTAKE = "CREATE_INTAKE";
export const CLEAR_NEW_INTAKE = "CLEAR_NEW_INTAKE";
export const SET_INTAKE_DATE_FILTER = "SET_INTAKE_DATE_FILTER";
export const SET_CUSTOM_FILTERS = "SET_CUSTOM_FILTERS";
export const FETCH_REPORTS = "FETCH_REPORTS";
export const FETCH_REPORT_CREDS = "FETCH_REPORT_CREDS";
export const FETCH_DIAGNOSES = "FETCH_DIAGNOSES";
export const FETCH_STAY_DIAGNOSES = "FETCH_STAY_DIAGNOSES";
export const UPDATE_INTAKE = "UPDATE_INTAKE";
export const DELETE_INTAKE = "DELETE_INTAKE";
export const DELETE_UPDATE = "DELETE_UPDATE";
export const FETCH_RATE_HISTORY = "FETCH_RATE_HISTORY";
export const UPDATE_UPDATE = "UPDATE_UPDATE";
export const CLEAR_INTAKE_FILE = "CLEAR_INTAKE_FILE";
export const FETCH_ALL_INTAKE_MEDS = "FETCH_ALL_INTAKE_MEDS";
export const FETCH_ALL_INTAKE_CONDITIONS = "FETCH_ALL_INTAKE_CONDITIONS";
export const GET_CURRENT_AUTHENTICATED_USER = "GET_CURRENT_AUTHENTICATED_USER";
export const SET_SERVICE_HISTORY = "SET_SERVICE_HISTORY";
export const FETCH_ALL_INTAKE_SYMPTOMS = "FETCH_ALL_INTAKE_SYMPTOMS";
export const UPDATE_ALERT = "UPDATE_ALERT";
export const UPDATE_RATE_HISTORY = "UPDATE_RATE_HISTORY";
export const FETCH_ADLS = "FETCH_ADLS";
export const FETCH_ASSESSMENTS_LIST = "FETCH_ASSESSMENTS_LIST";
export const UPDATE_ALERT_IN_STORE = "UPDATE_ALERT_IN_STORE";
export const SET_ALERTS_FILTERS = "SET_ALERTS_FILTERS";
export const SET_INTAKE_FILTERS = "SET_INTAKE_FILTERS";
export const SET_UPDATE_FILTERS = "SET_UPDATE_FILTERS";
export const FETCH_RUG_CODES = "FETCH_RUG_CODES";
export const CREATE_RUG_ASSESSMENT = "CREATE_RUG_ASSESSMENT";
export const SET_FACILITY = "SET_FACILITY";
