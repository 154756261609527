import React, { useContext, useState } from "react";
import moment from "moment";
import TherapyGraph from "./TherapyGraph";
import TherapyTable from "./TherapyTable";
import { DateFilter } from "../../common/DateFilter";
import ToggleButton from "../../common/ToggleButton";
import LoadingIndicator from "../../common/LoadingIndicator";
import { useTherapyEventsForStay } from "../../api/caseManagementApi";
import { sortBy } from "lodash";
import { ADL_GRAPH_ENABLED } from "../../utilities/appSettings";
import { PatientStayContext } from "../../PatientPage";

export const Therapy = ({ setCustomFilters }) => {
  const { stay } = useContext(PatientStayContext);

  const [filters, setFilters] = useState({
    startDate: moment().startOf("day").subtract(14, "days"),
    endDate: moment().startOf("day"),
  });

  const [chartType, setChartType] = useState("Chart");

  const therapyQuery = useTherapyEventsForStay(stay.id);

  if (therapyQuery.isLoading) {
    return <LoadingIndicator />;
  }

  const toggleChartType = (type) => {
    setChartType(type);
  };

  const handleDateFilterChange = (selectedValue) => {
    setFilters((x) => ({ ...x, ...selectedValue }));
  };

  const showChart = chartType === "Chart";

  const therapyData = getAndSortTherapyData(therapyQuery.data, stay.admitDate);

  const visibleData = bufferTherapyEvents(therapyData, stay, filters).filter((record) => {
    const date = moment(record.date);
    return (
      (!filters.startDate || date.isSameOrAfter(filters.startDate, "day")) &&
      (!filters.endDate || date.isSameOrBefore(filters.endDate, "day"))
    );
  });

  return therapyData.length > 0 ? (
    <>
      <div className="d-flex justify-end p-xs">
        <DateFilter endDate={filters.endDate} startDate={filters.startDate} setDateFilter={handleDateFilterChange} />
      </div>

      {ADL_GRAPH_ENABLED && (
        <div className="bg-light m-l-sm m-r-sm" style={{ marginTop: 10 }}>
          <ToggleButton titleLeft="Graph" titleRight="Chart" value={chartType} onToggle={toggleChartType} />
        </div>
      )}

      <div className="col" style={{ marginTop: 20 }}>
        {showChart ? (
          <TherapyTable data={visibleData} />
        ) : (
          ADL_GRAPH_ENABLED && (
            <TherapyGraph width="100%" stay={stay} data={visibleData} setCustomFilters={setCustomFilters} />
          )
        )}
      </div>
    </>
  ) : (
    <div style={{ marginTop: 20 }}>There is no data available.</div>
  );
};

const getAndSortTherapyData = (therapyData, admitDate) => {
  return sortBy(
    Object.values(therapyData).map((item) => {
      const timeDiff = moment(item.date);
      return {
        ...item,
        ...item.minutesByType,
        total: Object.values(item.minutesByType).reduce((a, b) => a + b, 0),
        dayInStay: Math.ceil(moment.duration(timeDiff.diff(admitDate)).asDays()) + 1,
      };
    }),
    "date",
    "asc"
  );
};

const createZeroDay = (date, stayId, admitDate) => {
  return {
    date: date,
    stayId: stayId,
    notes: "",
    setting: "N/A",
    OT: 0,
    PT: 0,
    ST: 0,
    PS: 0,
    RS: 0,
    total: 0,
    dayInStay: Math.ceil(moment.duration(moment(date).diff(admitDate)).asDays()) + 1,
  };
};

const bufferTherapyEvents = (data, stay, filters) => {
  const bufferedData = [];
  const { startDate, endDate } = filters;
  const { admitDate } = stay;

  let currentDay = startDate ? startDate.startOf("day") : moment(admitDate);

  if (currentDay.isSameOrBefore(moment(admitDate))) {
    currentDay = moment(admitDate);
  }
  let lastDay;

  if (!endDate || endDate.isSameOrAfter(moment().startOf("day"))) {
    lastDay = moment().subtract(1, "days");
  } else {
    lastDay = endDate;
  }

  let dataObject =
    data.length > 0
      ? data.reduce((previousValue, currentValue) => {
          if (currentValue.date in previousValue) {
            compressRow(previousValue[currentValue.date], currentValue);
            return previousValue;
          }
          return { ...previousValue, [currentValue.date]: { ...currentValue } };
        }, {})
      : data;

  while (currentDay <= lastDay) {
    bufferedData.push(
      dataObject[currentDay.format("YYYY-MM-DD")] ||
        createZeroDay(currentDay.format("YYYY-MM-DD"), stay.id, stay.admitDate)
    );
    currentDay = moment(currentDay).add(1, "days");
  }
  return bufferedData;
};

const compressRow = (curRow, newRow) => {
  let staticColumns = ["date", "dayInStay", "stayId"];
  let valuesArrays = true;
  if (curRow["setting"].constructor !== Array) valuesArrays = false;
  for (let key in curRow) {
    if (staticColumns.includes(key)) continue;
    if (!valuesArrays) curRow[key] = [curRow[key]];
    curRow[key].push(newRow[key]);
  }
};
