import React, { createContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { setCustomFilters, setServiceHistory } from "./store/actions";
import { staysSelector } from "./store/selectors";
import { useFullName, launchEmailClient } from "./utilities";
import PatientInfo from "./patient/PatientInfo";
import { Switch, Route, NavLink } from "react-router-dom";
import { Alerts } from "./patient/Alerts";
import { Therapy } from "./patient/Therapy";
import { Orders } from "./patient/Orders";
import { RateHistory } from "./patient/RateHistory";
import { Adls } from "./patient/Adls";
import { FunctionList } from "./patient/FunctionList";
import { NurseNotes } from "./patient/NurseNotes";
import Panel from "./common/Panel";
import { StayServiceSummary } from "./patient/ServiceSummary";
import { AssessmentsList } from "./patient/AssessmentsList";
import { Diagnoses } from "./patient/Diagnoses";
import { facilityToggle } from "./common/FeatureToggle";
import { withMixpanel } from "react-mixpanel-browser";
import LoadingIndicator from "./common/LoadingIndicator";
import {
  useInsurancePlans,
  usePayerById,
  useRateHistoryForStay,
  useStayById,
  useStayServices,
} from "./api/caseManagementApi";
import { ADLS_ENABLED, DIAGNOSIS_ENABLED, RATE_HISTORY_ENABLED } from "./utilities/appSettings";

const CustomToolTip = ({ description }) => {
  // Displays a "More Info" line of text and a tool tip
  const [canView, setCanView] = useState(false);

  return (
    <div className="more-info-wrapper" onMouseEnter={() => setCanView(true)} onMouseLeave={() => setCanView(false)}>
      {/* more info */}
      <i className="fa fa-info" />
      <p>More Info</p>

      {
        /* custom tool tip */
        canView && (
          <div id="custom-tool-tip">
            <div>{description}</div>
            <div className="tool-tip-arrow"></div>
          </div>
        )
      }
    </div>
  );
};

export const PatientStayContext = createContext(null);

export const PatientPage = (props) => {
  const { id, mixpanel, user: { email } = {}, setCustomFilters, match, setServiceHistory } = props;

  const [payerId, setPayerId] = useState(undefined);
  const [patientPayers, setPatientPayers] = useState(undefined);
  const [insurancePlans, setInsurancePlans] = useState(undefined);

  const stayQuery = useStayById(id);
  const rateHistoryQuery = useRateHistoryForStay(id);
  const stayServicesQuery = useStayServices(id);
  const payerQuery = usePayerById(payerId);
  const insurancePlansQuery = useInsurancePlans(patientPayers);

  const insurancePlansLoading = insurancePlansQuery.length === 0 || insurancePlansQuery.some((x) => x.isLoading);

  useEffect(() => {
    mixpanel && mixpanel.track("viewed patient");
  }, []);

  useEffect(() => {
    if (stayQuery.data) {
      setPayerId(stayQuery.data.payerId ?? null);
    }
  }, [stayQuery.data]);

  useEffect(() => {
    if (rateHistoryQuery.data && payerQuery.data) {
      const patientPayers = [...new Set([payerQuery.data.id, ...rateHistoryQuery.data.map((r) => r.payerId)])];

      setPatientPayers(patientPayers);
      setServiceHistory(id, patientPayers);
    }
  }, [payerQuery.data, rateHistoryQuery.data]);

  useEffect(() => {
    if (!insurancePlansLoading && stayQuery.data) {
      const plans = insurancePlansQuery.flatMap((x) => x.data);
      setInsurancePlans(plans);
    }
  }, [insurancePlansLoading]);

  if (stayQuery.isLoading || (payerQuery.isLoading && insurancePlansLoading)) {
    return <LoadingIndicator />;
  }

  const tabs = getTabs(
    match.url,
    ADLS_ENABLED,
    RATE_HISTORY_ENABLED,
    DIAGNOSIS_ENABLED,
    facilityToggle("FUNCTIONS", stayQuery.data.facId)
  );

  return (
    <PatientStayContext.Provider
      value={{ stay: stayQuery.data, services: stayServicesQuery.data, patientPayers: patientPayers }}
    >
      <PatientHeader patientName={stayQuery.data.patient} mixpanel={mixpanel} email={email} />
      {payerQuery.isLoading || insurancePlansLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <PatientInfo
            location={stayQuery.data.location}
            admitDate={stayQuery.data.admitDate}
            dischargeDate={stayQuery.data.dischargeDate}
            payerName={payerQuery.data ? payerQuery.data.displayName : undefined}
            billingInsurancePlan={
              insurancePlans ? insurancePlans.find((x) => x.id === stayQuery.data.billingInsurancePlanId) : undefined
            }
          />
          <div className="patient-content">
            <ul className="nav nav-tabs">
              {tabs.map((navTab) => (
                <li
                  role="presentation"
                  key={navTab.path}
                  className={window.location.pathname === navTab.path ? "active" : ""}
                >
                  <NavLink to={navTab.path} exact>
                    {navTab.title}
                  </NavLink>
                </li>
              ))}
            </ul>
            <Switch>
              {tabs.map((tab) => (
                <Route
                  key={tab.path}
                  path={tab.path}
                  exact={!!tab.exact}
                  component={(props) => {
                    const selectedTab = tabs.find(({ path }) => path === props.match.path);
                    return (
                      <div className="hpanel tab-pane">
                        <div className="panel-body">
                          <CustomToolTip description={selectedTab.infoLabel} />
                          <tab.component
                            {...props}
                            isLoading={(payerId && payerQuery.isLoading) || rateHistoryQuery.isLoading}
                            payer={payerQuery.data}
                            insurancePlans={insurancePlans}
                            setCustomFilters={setCustomFilters}
                          />
                        </div>
                      </div>
                    );
                  }}
                />
              ))}
            </Switch>
          </div>
        </>
      )}
    </PatientStayContext.Provider>
  );
};

const PatientHeader = (props) => {
  const { patientName, mixpanel, email } = props;
  return (
    <Panel style={{ margin: "-16px -16px 0 -16px" }}>
      <div className="d-flex align-center">
        <h1 className="flex-1-auto">{useFullName(patientName)}</h1>
        <button
          className="btn btn-default"
          onClick={() => {
            launchEmailClient("patient record");
            mixpanel.track("user shares", { email: email });
          }}
        >
          Share
        </button>
      </div>
    </Panel>
  );
};

const getTabs = (url, adlsToggle, rateHistoryToggle, diagnosisToggle, functionToggle) => {
  const tabsConfig = [
    {
      title: "Service Summary",
      path: url,
      exact: true,
      component: StayServiceSummary,
      infoLabel:
        'The Service Summary displays the clinical service rules for the specific patient\'s payer. Any payer rules highlighted in blue indicate that clinical orders have been detected for that rule in the past 15 days. Those orders may be viewed by clicking the highlighted row or “Services Delivered” link off to the right. Orders older than 15 days will no longer highlight the rule in blue but can still be viewed by selecting "Services Delivered".',
    },
    {
      title: "Alerts",
      path: `${url}/alerts`,
      component: Alerts,
      infoLabel:
        "Alerts display a history of all the alerts for the specific patient. The alerts remain part of the patient record and are never deleted. You can update the alert status here as needed.",
    },
    {
      title: "Therapy",
      path: `${url}/therapy`,
      component: Therapy,
      infoLabel:
        "Therapy displays therapy time delivery for the specific patient for OT, PT and ST. Therapy minutes displayed are from your EHR or therapy software and not generated by Medasync.",
    },
    {
      title: "Orders",
      path: `${url}/orders`,
      component: Orders,
      infoLabel:
        "Orders is a convenient listing of all clinical orders from your EHR system and not generated by Medasync.",
    },
    {
      title: "Nurse Notes",
      path: `${url}/nurseNotes`,
      component: NurseNotes,
      infoLabel:
        "Nursing Notes is a convenient listing of all notes from your EHR system and not generated by Medasync.",
    },
    adlsToggle && {
      title: "ADLs",
      path: `${url}/adls`,
      component: Adls,
      infoLabel: "ADLs displays Activities of Daily Living report from your EHR system and not generated by Medasync.",
    },
    rateHistoryToggle && {
      title: "Rate History",
      path: `${url}/rateHistory`,
      component: RateHistory,
      infoLabel:
        "The rate data displayed here is based on the Billing Record from your EHR.  Since this data is tied to the patient billing record, it  may not reflect the most current CMG or RUG from your clinical assessments.",
    },
    {
      title: "Assessments List",
      path: `${url}/assessmentsList`,
      component: AssessmentsList,
      infoLabel: "The assessment list displays a running list of completed state MDS assessments.",
    },
    diagnosisToggle && {
      title: "Diagnoses",
      path: `${url}/diagnoses`,
      component: Diagnoses,
      infoLabel: "Diagnoses is a compilation of Diagnoses from your EHR.",
    },
    functionToggle && {
      title: "Function",
      path: `${url}/functionList`,
      component: FunctionList,
      infoLabel:
        "For each category below, MedaSync displays the function response that is documented in the EHR most often during a given day and the Function Index is the product of all responses for the three-day look back period.",
    },
  ].filter(Boolean);

  return tabsConfig;
};

function mapStateToProps(state, initialProps) {
  const { match: { params: { id } = {} } = {} } = initialProps;
  const stay = staysSelector(state)[id];
  const payers = (id && state.serviceHistory && state.serviceHistory.all[id]) || [];
  return {
    id,
    payers,
    user: state.user,
    billingInsurancePlan: (stay || {}).billingInsurancePlan,
    deliveredInsurancePlan: (stay || {}).deliveredInsurancePlan,
  };
}

export default connect(mapStateToProps, {
  setCustomFilters,
  setServiceHistory,
})(withMixpanel(PatientPage));
