import { useQuery, useQueries, useMutation } from "@tanstack/react-query";
import { API } from "aws-amplify";
import { normalize } from "../store/reducers";

const apiName = "CaseManagementApi";
const defaultInit = { response: true };
const DATE_FMT = "YYYY-MM-DD";

export function useStaysByFacility(facilityId, page, pageSize, filters) {
  const queryFn = () => {
    let filterParams = {};

    if (filters) {
      Object.entries(filters).forEach(([k, v]) => {
        if (k === "payers") {
          k = "payerIds";
        }
        if (Array.isArray(v)) {
          filterParams[k] = v
            .filter((x) => x.value)
            .map((x) => x.value)
            .join(",");
        } else {
          if (k.includes("Date") && v) {
            filterParams[k] = v.format(DATE_FMT);
          } else {
            filterParams[k] = v;
          }
        }
      });
    }

    filterParams = Object.fromEntries(Object.entries(filterParams).filter(([k, v]) => v));

    return API.get(apiName, `/stays/facility/${facilityId}`, {
      ...defaultInit,
      queryStringParameters: {
        page: page,
        pageSize: pageSize,
        detailed: false,
        ...filterParams,
      },
    }).then((response) => response.data);
  };

  return useQuery({
    queryFn: queryFn,
    queryKey: ["fetchStaysByFacility", facilityId, page, pageSize, filters],
  });
}

export function useFacilityPayers(facilityId) {
  const fetchPayers = () => {
    return API.get(apiName, "/payers", defaultInit)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  };

  return useQuery({
    queryKey: ["facilityPayers", facilityId],
    queryFn: fetchPayers,
    select: (data) => data.filter((x) => x.facilityId === facilityId),
  });
}

export function useStayById(stayId) {
  const queryFn = () => {
    return API.get(apiName, `/stays/${stayId}`, defaultInit).then((response) => response.data);
  };
  return useQuery({
    queryKey: ["stayById", stayId],
    queryFn: queryFn,
  });
}

export function usePayerById(payerId) {
  const queryFn = () => {
    return API.get(apiName, `/payers/${payerId}`, defaultInit).then((response) => response.data);
  };
  return useQuery({
    queryKey: ["payerById", payerId],
    queryFn: queryFn,
    enabled: !!payerId,
  });
}

export function usePayers(payerIds) {
  const queryFn = (payerId) => {
    return API.get(apiName, `/payers/${payerId}`, defaultInit).then((response) => response.data);
  };

  return useQueries({
    queries: payerIds
      ? payerIds.map((payerId) => ({
          queryKey: ["payers", payerId],
          queryFn: () => queryFn(payerId),
        }))
      : [],
  });
}

export function useRateHistoryForStay(stayId) {
  const queryFn = () => {
    return API.get(apiName, `/stays/${stayId}/rateHistory`, defaultInit).then((response) => response.data);
  };

  return useQuery({
    queryKey: ["rateHistoryForStay"],
    queryFn: queryFn,
  });
}

export function useInsurancePlans(payerIds) {
  const queryFn = (payerId) =>
    API.get(apiName, `/payers/${payerId}/insurancePlans`, defaultInit).then((response) => response.data);

  return useQueries({
    queries: payerIds
      ? payerIds.map((x) => ({
          queryKey: ["insurancePlans", x],
          queryFn: () => queryFn(x),
        }))
      : [],
  });
}

export function useOrdersForStay(stayId) {
  const queryFn = () => API.get(apiName, `/stays/${stayId}/orders`, defaultInit).then((response) => response.data);

  return useQuery({
    queryKey: ["ordersForStay", stayId],
    queryFn: queryFn,
  });
}

export function useNurseNotesForStay(stayId) {
  const queryFn = () => API.get(apiName, `/stays/${stayId}/nurseNotes`, defaultInit).then((response) => response.data);

  return useQuery({
    queryKey: ["nurseNotesForStay", stayId],
    queryFn: queryFn,
  });
}

export function useAssessmentsForStay(stayId) {
  const queryFn = () =>
    API.get(apiName, `/stays/${stayId}/rugAssessments`, defaultInit).then((response) => response.data);

  return useQuery({
    queryKey: ["assessmentsForStay", stayId],
    queryFn: queryFn,
  });
}

export function useTherapyEventsForStay(stayId) {
  const queryFn = () =>
    API.get(apiName, `/stays/${stayId}/therapyEvents`, defaultInit).then((response) => response.data);

  return useQuery({
    queryKey: ["therapyEventsForStay", stayId],
    queryFn: queryFn,
  });
}

export function useAdlsForStay(stayId) {
  const queryFn = () => API.get(apiName, `/stays/${stayId}/adl`, defaultInit).then((response) => response.data);

  return useQuery({
    queryKey: ["adlsForStay", stayId],
    queryFn: queryFn,
  });
}

export function useAlerts(admitDate, endDate) {
  const params = {
    fromDate: admitDate.format(DATE_FMT),
  };
  if (endDate) {
    params.toDate = endDate.format(DATE_FMT);
  }

  const queryFn = () =>
    API.get(apiName, "/alerts", {
      ...defaultInit,
      queryStringParameters: params,
    }).then((response) => response.data);

  return useQuery({
    queryKey: ["alerts", admitDate, endDate],
    queryFn: queryFn,
  });
}

export function useAlertsForPatient(patientId, admitDate, endDate) {
  const params = {
    fromDate: admitDate.format(DATE_FMT),
  };
  if (endDate) {
    params.toDate = endDate.format(DATE_FMT);
  }
  if (patientId) {
    params.patientId = patientId;
  }

  const queryFn = () =>
    API.get(apiName, "/alerts", {
      ...defaultInit,
      queryStringParameters: params,
    }).then((response) => response.data);

  return useQuery({
    queryKey: ["alertsForPatient", patientId, admitDate, endDate],
    queryFn: queryFn,
  });
}

export function useAlertCategories() {
  const queryFn = () =>
    API.get(apiName, "/alerts/categories", defaultInit).then((response) => normalize(response.data));

  return useQuery({
    queryKey: ["alertCategories"],
    queryFn: queryFn,
  });
}

export function useAlertStatuses() {
  const queryFn = () => API.get(apiName, "/alerts/statuses", defaultInit).then((response) => response.data);

  return useQuery({
    queryKey: ["alertStatuses"],
    queryFn: queryFn,
  });
}

export function useAlertTypes() {
  const queryFn = () => API.get(apiName, "/alerts/types", defaultInit).then((response) => response.data);

  return useQuery({
    queryKey: ["alertTypes"],
    queryFn: queryFn,
  });
}

export function useFunctionEvaluations(stayId) {
  const queryFn = () =>
    API.get(apiName, `/stays/${stayId}/functionScores`, defaultInit).then((response) => response.data);

  return useQuery({
    queryKey: ["functionScores", stayId],
    queryFn: queryFn,
  });
}

export function useFunctionData(stayId) {
  const queryFn = () =>
    API.get(apiName, `/stays/${stayId}/functionData`, defaultInit).then((response) => response.data);

  return useQuery({
    queryKey: ["functionData", stayId],
    queryFn: queryFn,
  });
}

export function useUpdateAlert() {
  const mutationFn = (updatedAlert) => {
    const encodedAlertId = encodeURIComponent(updatedAlert.id);
    return API.put(apiName, `/alerts/${encodedAlertId}`, {
      ...defaultInit,
      body: { status: updatedAlert.status_id, status_note: updatedAlert.status_note },
    });
  };

  return useMutation({
    mutationFn: mutationFn,
  });
}

export function useStayServices(stayId) {
  const queryFn = () => API.get(apiName, `/stays/${stayId}/services`, defaultInit).then((response) => response.data);

  return useQuery({
    queryKey: ["stayServices", stayId],
    queryFn: queryFn,
  });
}
