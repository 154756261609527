import React, { useContext, useState } from "react";
import moment from "moment";
import Table from "../common/Table";
import { DateFilter } from "../common/DateFilter";
import LoadingIndicator from "../common/LoadingIndicator";
import { AlertDialog } from "./AlertDialog";
import { useAlertsForPatient, useAlertCategories, useAlertTypes, useAlertStatuses } from "../api/caseManagementApi";
import { PatientStayContext } from "../PatientPage";

export const config = (categoryStatics, typeStatics, statusStatics) => {
  return {
    columns: [
      {
        title: "Status",
        transformer: (alert) => {
          return <AlertDialog alert={alert} statuses={statusStatics} />;
        },
        sortValue: (data) => data.status || "Open",
        sortable: true,
        headerClassName: "text-center",
      },
      {
        title: "Date",
        key: "date",
        transformer: (date) => moment(date).format("M/DD/YYYY"),
        sortValue: (data) => moment(data.date).unix(),
        sortable: true,
      },
      {
        title: "Type",
        transformer: (alert) => {
          return typeStatics && typeStatics[alert.type_id] ? typeStatics[alert.type_id].name : "-";
        },
        sortable: true,
        headerClassName: "w-xxs",
      },
      {
        title: "Category",
        transformer: (alert) => {
          return categoryStatics && categoryStatics[alert.category_id] ? categoryStatics[alert.category_id].name : "-";
        },
        sortable: true,
      },
      {
        title: "Description",
        key: "description",
        sortable: true,
      },
    ],
    defaultSort: {
      column: "Date",
      direction: "descending",
    },
  };
};

export const Alerts = () => {
  const { stay } = useContext(PatientStayContext);

  const [filters, setFilters] = useState({ startDate: null, endDate: null });

  const alertsQuery = useAlertsForPatient(
    stay.patient.id,
    moment(stay.admitDate),
    stay.dischargeDate ? moment(stay.dischargeDate) : null
  );

  const alertCategoriesQuery = useAlertCategories();
  const alertTypesQuery = useAlertTypes();
  const alertStatusesQuery = useAlertStatuses();

  if (alertsQuery.isLoading || alertStatusesQuery.isLoading) {
    return <LoadingIndicator />;
  }

  const handleDateFilterChange = (selectedValue) => {
    setFilters({ startDate: selectedValue.startDate, endDate: selectedValue.endDate });
  };

  const visibleAlerts = alertsQuery.data.filter((alert) => {
    const date = moment(alert.date);
    return (
      (!filters.startDate || date.isSameOrAfter(filters.startDate, "day")) &&
      (!filters.endDate || date.isSameOrBefore(filters.endDate, "day"))
    );
  });

  return alertsQuery.data.length > 0 ? (
    <Table
      data={visibleAlerts}
      config={config(alertCategoriesQuery.data, alertTypesQuery.data, alertStatusesQuery.data)}
      headerContent={
        <div className="d-flex justify-end p-xs">
          <DateFilter endDate={filters.endDate} startDate={filters.startDate} setDateFilter={handleDateFilterChange} />
        </div>
      }
    />
  ) : (
    <div style={{ marginTop: 20 }}>There are no alerts for this patient.</div>
  );
};
