import {
  SET_INTAKE_FILTERS,
  SET_ALERTS_FILTERS,
  SET_UPDATE_FILTERS,
  FETCH_ALERT_CATEGORIES,
  FETCH_ALERT_STATUSES,
} from "../actions/types";

const initialState = {
  alertFilters: {
    locations: [],
    status: [],
    categories: [],
  },
  intakeFilters: {
    facilities: [],
    status: [{ label: "Pending", value: "PENDING" }],
  },
  updateFilters: {
    payers: [],
    locations: [],
  },
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case SET_ALERTS_FILTERS:
      return {
        ...state,
        alertFilters: {
          ...state.alertFilters,
          [payload.filterKey]: payload.selectedValues,
        },
      };
    case `${SET_INTAKE_FILTERS}`:
      return {
        ...state,
        intakeFilters: {
          ...state.intakeFilters,
          [payload.filterKey]: payload.selectedValues,
        },
      };
    case `${SET_UPDATE_FILTERS}`:
      return {
        ...state,
        updateFilters: {
          ...state.updateFilters,
          [payload.filterKey]: payload.selectedValues,
        },
      };
    case `${FETCH_ALERT_CATEGORIES}_FULFILLED`:
      return {
        ...state,
        alertFilters: {
          ...state.alertFilters,
          categories: payload.data.map((category) => {
            return { label: category.name, value: category.id };
          }),
        },
      };
    case `${FETCH_ALERT_STATUSES}_FULFILLED`:
      const openStatus = payload.data.filter((s) => s.text === "Open");
      return {
        ...state,
        alertFilters: {
          ...state.alertFilters,
          status: openStatus.map((s) => {
            return { label: s.text, value: s.id };
          }),
        },
      };
    default:
      return state;
  }
}
