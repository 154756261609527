import { isToggleTurnedOnMemoized } from "../common/FeatureToggle";

export const ADD_DOC_AS_TEXT_ENABLED = isToggleTurnedOnMemoized("ADD_DOC_AS_TEXT");
export const ADL_GRAPH_ENABLED = isToggleTurnedOnMemoized("ADL_GRAPH");
export const ADLS_ENABLED = isToggleTurnedOnMemoized("ADLS");
export const CONDITIONS_ENABLED = isToggleTurnedOnMemoized("CONDITIONS");
export const CONDITIONS_INSIGHTS_ENABLED = isToggleTurnedOnMemoized("CONDITIONS_INSIGHTS");
export const DELETE_CONDITION_ENABLED = isToggleTurnedOnMemoized("DELETE_CONDITION");
export const DIAGNOSIS_ENABLED = isToggleTurnedOnMemoized("DIAGNOSIS");
export const DOCUMENTS_ENABLED = isToggleTurnedOnMemoized("DOCUMENTS");
export const EDIT_INTAKE_ENABLED = isToggleTurnedOnMemoized("EDIT_INTAKE");
export const RATE_HISTORY_ENABLED = isToggleTurnedOnMemoized("RATE_HISTORY");
export const SERVICE_NOTES_ENABLED = isToggleTurnedOnMemoized("SERVICE_NOTES");
