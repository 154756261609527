import React, { useContext, useState } from "react";
import moment from "moment";
import Table from "../common/Table";
import Filter from "../common/Filter";
import { DateFilter } from "../common/DateFilter";
import LoadingIndicator from "../common/LoadingIndicator";
import { sortBy, uniqBy } from "lodash";
import { PatientStayContext } from "../PatientPage";

const config = {
  columns: [
    {
      title: "Date",
      key: "date",
      transformer: (data) => moment(data).format("M/DD/YYYY"),
      sortValue: (data) => moment(data.date).unix(),
      sortable: true,
    },
    {
      title: "Note Type",
      key: "type",
      sortable: true,
    },
    {
      title: "Note",
      key: "note",
      sortable: true,
    },
    {
      title: "Author",
      key: "author",
      sortable: true,
    },
  ],
  defaultSort: {
    column: 0,
    direction: "descending",
  },
};

const FILTER_OPTIONS = [
  {
    label: "Alert Note",
    value: "Alert Note",
  },
  {
    label: "Behavior",
    value: "Behavior",
  },
  {
    label: "Case Management",
    value: "Case Management",
  },
  {
    label: "Health Status",
    value: "Health Status",
  },
  {
    label: "Health Status/PN",
    value: "Health Status/PN",
  },
  {
    label: "Lab Note",
    value: "Lab Note",
  },
  {
    label: "MDS",
    value: "MDS",
  },
  {
    label: "Notification Note",
    value: "Notification Note",
  },
  {
    label: "Nutrition (SPN)",
    value: "Nutrition (SPN)",
  },
  {
    label: "Nutrition Services",
    value: "Nutrition Services",
  },
  {
    label: "Physician's Progress Note",
    value: "Physician's Progress Note",
  },
  {
    label: "Progress Note(PN)",
    value: "Progress Note(PN)",
  },
  {
    label: "Restorative",
    value: "Restorative",
  },
  {
    label: "Skilled Note/PN",
    value: "Skilled Note/PN",
  },
  {
    label: "Skin/Wound Note",
    value: "Skin/Wound Note",
  },
  {
    label: "Social Services (SPN)",
    value: "Social Services (SPN)",
  },
  {
    label: "Therapy Note/PN",
    value: "Therapy Note/PN",
  },
  {
    label: "eMAR - Shift Level Administration Note",
    value: "eMAR - Shift Level Administration Note",
  },
  {
    label: "eMAR- Medication Administration Note",
    value: "eMAR- Medication Administration Note",
  },
];

export const NurseNotes = () => {
  const { services } = useContext(PatientStayContext);

  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
    nurseNotesType: [],
  });

  if (services === undefined) {
    return <LoadingIndicator />;
  }

  const filterOptions = sortBy(
    uniqBy(
      FILTER_OPTIONS.concat(
        services.notes.map(({ type }) => ({
          label: type,
          value: type,
        }))
      ),
      "label"
    ),
    "label"
  );

  const visibleNurseNotes = services.notes.filter((note) => {
    const date = moment(note.date);
    return (
      (!filters.nurseNotesType.length || filters.nurseNotesType.map(({ value }) => value).includes(note.type)) &&
      (!filters.startDate || date.isSameOrAfter(filters.startDate, "day")) &&
      (!filters.endDate || date.isSameOrBefore(filters.endDate, "day"))
    );
  });

  const handleFilterChange = (filters) => {
    setFilters((x) => ({ ...x, nurseNotesType: filters }));
  };

  const handleDateFilterChange = (selectedValue) => {
    setFilters((x) => ({ ...x, startDate: selectedValue.startDate, endDate: selectedValue.endDate }));
  };

  return services.notes.length > 0 ? (
    <Table
      data={visibleNurseNotes}
      config={config}
      headerContent={
        <>
          <div className="d-flex justify-end p-xs">
            <DateFilter
              endDate={filters.endDate}
              startDate={filters.startDate}
              setDateFilter={handleDateFilterChange}
            />
          </div>
          <Filter
            className="p-xs"
            label="Type"
            placeholder="Filter by..."
            value={filters.nurseNotesType}
            onChange={handleFilterChange}
            options={filterOptions}
            isMulti
          />
        </>
      }
    />
  ) : (
    <div style={{ marginTop: 20 }}>There aren't any notes for this patient.</div>
  );
};
